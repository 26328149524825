import { Component, Inject, Input, OnInit } from '@angular/core';
import { RestrictInputType } from '../../../directives/restrict-input.directive';
import {
  ciMaxCharacters,
  ciMinCharacters,
  FormErrors,
  internalIdLength,
  longTextMax,
  nameMaxLength,
  nameMinLength,
  phoneNumberLength,
} from '../../../utilities/constants';
import { MemberGender, Members } from '../../../../models/members';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { customLength } from '../../../validators/custom-length';
import { TranslationEnum } from 'src/assets/i18n/translation-enum';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { MemberDetailsService } from '../../../../services/member-details.service';
import { MemberSetting } from '../../../../models/member-logs/member-setting';
import { getCurrentGymCustomCardLength } from '../../../../store/selectors/user.selector';
import { filter, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { EntityService } from '../../../../services/entity.service';
import {
  getISOStringDateNoTime,
  validateMemberBirthday,
} from '../../../utilities/utility-functions';
import { saveToasterNotification } from '../../../../store/actions/misc.actions';
import {
  CreateToasterError,
  CreateToasterSuccess,
  errorsMessages,
} from '../../../../models/toaster-notification';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-check-in-settings',
  templateUrl: './check-in-settings.component.html',
  styleUrls: ['./check-in-settings.component.scss'],
})
export class CheckInSettingsComponent implements OnInit {
  restrictInputType = RestrictInputType;
  isBirthdayValid: boolean = true;

  formErrors = FormErrors;
  nameMinLength = nameMinLength;
  nameMaxLength = nameMaxLength;
  longTextMax = longTextMax;
  phoneNumberLength = phoneNumberLength;
  ciMaxCharacters = ciMaxCharacters;
  ciMinCharacters = ciMinCharacters;
  internalIdLength = internalIdLength;
  gender = [MemberGender.Female, MemberGender.Male];
  gymCustomCardLength?: number;
  memberBirthday: NgbDate = new NgbDate(null, null, null);
  member: Members = null;
  saveInProgress = false;

  @Input() set info(value: { locationId: string; memberId: string }) {
    this.locationId = value.locationId;
    this.memberId = value.memberId;
    this.updateMemberSettings();
    this.getMemberData();
  }

  locationId: string;
  memberId: string;

  memberForm = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(nameMinLength),
      Validators.maxLength(nameMaxLength),
      Validators.pattern('[a-zA-Z0-9 ]+'),
    ]),
    phone: new FormControl('', [
      Validators.minLength(phoneNumberLength),
      Validators.maxLength(phoneNumberLength),
    ]),
    email: new FormControl('', [
      Validators.pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$'),
    ]),
    birthdate: new FormControl(),
    sex: new FormControl(),
    memberCI: new FormControl('', [
      Validators.minLength(ciMinCharacters),
      Validators.maxLength(ciMaxCharacters),
    ]),
    emPhone: new FormControl('', [
      Validators.minLength(phoneNumberLength),
      Validators.maxLength(phoneNumberLength),
    ]),
    emName: new FormControl('', [
      Validators.minLength(nameMinLength),
      Validators.maxLength(nameMaxLength),
      Validators.pattern('[a-zA-Z0-9 ]+'),
    ]),
    address: new FormControl('', [
      Validators.maxLength(longTextMax),
      Validators.pattern('[a-zA-Z0-9 ,.-]+'),
    ]),
    userCode: new FormControl({ value: '', disabled: true }, [
      customLength(13),
    ]),
    internalId: new FormControl('', [
      Validators.maxLength(internalIdLength),
      Validators.pattern('[a-zA-Z0-9-]+'),
    ]),
    customCardCode: new FormControl({ value: '', disabled: true }),
    details: new FormControl('', Validators.maxLength(longTextMax)),
    lockerKey: new FormControl(),
    group: new FormControl(''),
  });

  TranslationEnum = TranslationEnum;
  settings: MemberSetting[] = [];
  private destroy: Subject<boolean> = new Subject<boolean>();

  constructor(
    private memberService: MemberDetailsService,
    @Inject('MemberService')
    private membersService: EntityService<Members>,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.store
      .select(getCurrentGymCustomCardLength)
      .pipe(takeUntil(this.destroy))
      .subscribe((data) => {
        this.gymCustomCardLength = data;
        if (this.gymCustomCardLength) {
          this.memberForm
            .get('customCardCode')
            .setValidators([
              Validators.maxLength(this.gymCustomCardLength),
              Validators.pattern('[a-zA-Z0-9- ]+'),
            ]);
        } else {
          this.memberForm.get('customCardCode').clearValidators();
        }
      });
  }

  getMemberData() {
    this.membersService
      .getEntity(this.memberId, this.locationId)
      .pipe(filter((member) => !!member))
      .subscribe((value) => {
        this.member = value;
        this.setFormData();
      });
  }

  hasErrors(control: AbstractControl) {
    return control.invalid && (control.dirty || control.touched);
  }

  setFormData() {
    if (this.member.birthdate) {
      let birthdate = new Date(this.member.birthdate);
      this.memberBirthday = NgbDate.from({
        day: birthdate.getDate(),
        month: birthdate.getMonth() + 1,
        year: birthdate.getFullYear(),
      });
    }
    this.memberForm.get('name').setValue(this.member.name);
    this.memberForm.get('address').setValue(this.member.address);
    this.memberForm.get('phone').setValue(this.member.phoneNumber);
    this.memberForm.get('email').setValue(this.member.email);
    this.memberForm.get('sex').setValue(this.member.sex);
    this.memberForm.get('memberCI').setValue(this.member.documentId);
    this.memberForm.get('emPhone').setValue(this.member.emergencyPhone);
    this.memberForm.get('emName').setValue(this.member.emergencyName);
    this.memberForm.get('userCode').setValue(this.member.barcode || null);
    this.memberForm.get('internalId').setValue(this.member.internalId);
    this.memberForm.get('details').setValue(this.member.freeText);
    this.memberForm.get('lockerKey').setValue(this.member.lockerKey);
    this.memberForm.get('customCardCode').setValue(this.member.barcode);
    this.memberForm.get('group').setValue(this.member.memberGroupDto);
  }

  updateMemberSettings() {
    this.memberService
      .getMemberSettings(this.memberId, this.locationId)
      .subscribe((data) => (this.settings = data));
  }

  saveChanges() {
    this.isBirthdayValid = validateMemberBirthday(this.memberBirthday);
    if (
      this.memberForm?.invalid ||
      this.saveInProgress ||
      !this.isBirthdayValid
    ) {
      return;
    }

    this.saveInProgress = true;
    const member = {} as Members;
    member.name = this.memberForm.get('name').value;
    member.address = this.memberForm.get('address').value;
    member.email = this.memberForm.get('email').value;
    member.phoneNumber = this.memberForm.get('phone').value;
    member.documentId = this.memberForm.get('memberCI').value;
    member.sex = this.memberForm.get('sex').value;
    member.lockerKey = this.memberForm.get('lockerKey')?.value;
    member.birthdate =
      this.memberBirthday?.day &&
      this.memberBirthday?.month &&
      this.memberBirthday?.year
        ? getISOStringDateNoTime(this.memberBirthday)
        : null;
    member.emergencyPhone = this.memberForm.get('emPhone').value;
    member.emergencyName = this.memberForm.get('emName').value;
    member.freeText = this.memberForm.get('details').value;
    member.locationId = this.locationId;
    member.id = this.memberId;
    member.internalId = this.memberForm.get('internalId')?.value;
    member.groupId = this.memberForm.get('group')?.value?.id;

    this.membersService
      .modifyEntity(this.member.id, member)
      .subscribe((value) => {
        this.saveInProgress = false;
        if (!value) {
          this.store.dispatch(
            saveToasterNotification({
              toasterNotification: CreateToasterError(),
            })
          );
        } else {
          const error = value instanceof HttpErrorResponse;
          if (error) {
            this.store.dispatch(
              saveToasterNotification({
                toasterNotification: CreateToasterError(
                  errorsMessages.get((value as any).error.errorType)
                ),
              })
            );
          } else {
            this.member = value;
            this.setFormData();
            this.store.dispatch(
              saveToasterNotification({
                toasterNotification: CreateToasterSuccess(),
              })
            );
          }
        }
      });
  }

  protected readonly RestrictInputType = RestrictInputType;
}
