import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MemberDetailsService } from '../../../../../services/member-details.service';
import { Members } from '../../../../../models/members';
import { saveToasterNotification } from '../../../../../store/actions/misc.actions';
import {
  CreateToasterError,
  CreateToasterSuccess,
} from '../../../../../models/toaster-notification';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../store';
import { TranslationEnum } from '../../../../../../assets/i18n/translation-enum';

@Component({
  selector: 'app-change-card',
  templateUrl: './change-card.component.html',
  styleUrls: ['./change-card.component.scss'],
})
export class ChangeCardComponent implements OnInit {
  changeCard: boolean;

  member: Members;

  locationId: string;

  barcode: string;

  TranslationEnum = TranslationEnum;

  constructor(
    private activeModal: NgbActiveModal,
    private store: Store<AppState>,
    private memberDetailsService: MemberDetailsService
  ) {}

  ngOnInit(): void {}

  closeModal() {
    this.activeModal?.close();
  }

  confirm() {
    this.memberDetailsService
      .changeCard({
        locationId: this.locationId,
        memberId: this.member.id,
        barcode: this.barcode,
      })
      .subscribe((result) => {
        this.closeModal();

        if (!result) {
          this.store.dispatch(
            saveToasterNotification({
              toasterNotification: CreateToasterError(),
            })
          );
          return;
        }
        this.member.barcode = null;
        this.store.dispatch(
          saveToasterNotification({
            toasterNotification: CreateToasterSuccess(),
          })
        );
      });
  }
}
