import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TranslationEnum } from '../../../../assets/i18n/translation-enum';
import { ChangeCardComponent } from '../../../containers/gym/members/members-edit/change-card/change-card.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Members } from '../../../models/members';
import { ActivatedRoute, Router } from '@angular/router';
import { saveToasterNotification } from '../../../store/actions/misc.actions';
import {
  CreateToasterError,
  CreateToasterSuccess,
} from '../../../models/toaster-notification';
import { MemberDetailsService } from '../../../services/member-details.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { filter, map, take } from 'rxjs/operators';

@Component({
  selector: 'app-member-more-actions',
  templateUrl: './member-more-actions.component.html',
  styleUrls: ['./member-more-actions.component.scss'],
})
export class MemberMoreActionsComponent implements OnInit {
  @Input()
  public member: Members;

  @Input()
  public locationId: string;

  @ViewChild('confirmationModal', { static: true })
  public confirmationModal: TemplateRef<any>;

  @Output()
  public memberDeleted = new EventEmitter();

  public barcode: string;
  public modalRef: NgbModalRef;
  public actionLoading: boolean;

  public readonly TranslationEnum = TranslationEnum;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private memberDetailsService: MemberDetailsService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.route.queryParams
      .pipe(
        filter((value) => !!value?.barcode),
        map((value) => value.barcode)
      )
      .subscribe((barcode) => {
        this.barcode = barcode;
        if (this.modalRef.componentInstance) {
          this.modalRef.componentInstance.barcode = barcode;
        }
      });
  }

  public onChangeCard(): void {
    this.modalRef = this.modalService.open(ChangeCardComponent, {
      windowClass: 'modal-change-card',
    });

    this.modalRef.componentInstance.changeCard = true;
    this.modalRef.componentInstance.member = this.member;
    this.modalRef.componentInstance.locationId = this.locationId;
    this.modalRef.componentInstance.barcode = this.barcode;
    this.router.navigate([this.router.routerState.snapshot.url], {
      queryParams: { changeCard: true },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });

    this.modalRef?.hidden.subscribe(() =>
      this.router.navigate([], {
        queryParams: {},
        replaceUrl: true,
      })
    );
  }

  public releaseCard(): void {
    this.modalRef = this.modalService.open(ChangeCardComponent, {
      windowClass: 'modal-change-card',
    });

    this.modalRef.componentInstance.changeCard = false;
    this.modalRef.componentInstance.member = this.member;
    this.modalRef.componentInstance.locationId = this.locationId;
    this.barcode = null;

    this.router.navigate([this.router.routerState.snapshot.url], {
      queryParams: { changeCard: false },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });

    this.modalRef?.hidden.subscribe(() =>
      this.router.navigate([], {
        queryParams: {},
        replaceUrl: true,
      })
    );
  }

  public resendRegisterEmail(): void {
    if (this.actionLoading) {
      return;
    }

    this.actionLoading = true;
    this.memberDetailsService
      .sendRegisterEmail(this.member.id, this.locationId)
      .subscribe((result) => {
        this.store.dispatch(
          saveToasterNotification({
            toasterNotification: !result
              ? CreateToasterError()
              : CreateToasterSuccess(),
          })
        );
        this.actionLoading = false;
      });
  }

  public openModalDelete(): void {
    this.modalService
      .open(this.confirmationModal)
      .closed.pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.memberDetailsService
            .deleteMember(this.member.id, this.locationId)
            .subscribe(() => {
              this.store.dispatch(
                saveToasterNotification({
                  toasterNotification: CreateToasterSuccess(
                    'The member was deleted successfully!'
                  ),
                })
              );
              this.memberDeleted.emit();
              this.router.navigate(['/gym/members']);
            });
        }
      });
  }
}
