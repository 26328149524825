<div class="change-card-wrapper">
  <div class="title">
    {{
      (changeCard
        ? TranslationEnum.Member.ACTION_CHANGE_CARD
        : TranslationEnum.Member.ACTION_RELEASE_CARD
      ) | translate
    }}
    <img
      src="assets/images/icons/close-gray.svg"
      class="close-icon"
      (click)="closeModal()"
    />
  </div>
  <div class="content">
    <img src="assets/images/member/credit-card.svg" class="credit-card" />
    <ng-container *ngIf="changeCard; else releaseCard">
      <p
        class="info-text mb-4"
        [innerHtml]="
          TranslationEnum.Member.CONFIRMATION_CHANGE_CARD | translate
        "
      ></p>
      <input
        class="barcode"
        type="text"
        [placeholder]="TranslationEnum.Member.SWIPE_NEW_CARD | translate"
        [value]="barcode ? barcode : ''"
        readonly
      />
    </ng-container>
    <ng-template #releaseCard>
      <p
        class="info-text mb-4"
        [innerHtml]="
          TranslationEnum.Member.CONFIRMATION_RELEASE_CARD | translate
        "
      ></p>
    </ng-template>
  </div>
  <div class="actions text-center">
    <button class="btn mr-3 btn-cancel" (click)="closeModal()">
      {{ TranslationEnum.Common.CLOSE | translate }}
    </button>
    <button class="btn btn-confirm" (click)="confirm()">
      {{ TranslationEnum.Common.CONFIRM | translate }}
    </button>
  </div>
</div>
