<div class="custom-input">
  <span class="label">
    {{ TranslationEnum.Member.GROUP | translate }}
  </span>
  <ng-select
    #groupSelect
    bindLabel="name"
    class="mb-1"
    id="group"
    [formControl]="groupControl"
    [items]="groups"
    [multiple]="false"
    [maxSelectedItems]="1"
    (search)="searchGroupsSubject.next($event)"
    (change)="detectChange($event)"
  >
  </ng-select>
  <ng-template #groupNotFound>
    <span
      class="ng-option"
      [class.option-disabled]="!groupCreateName"
      (click)="addGroup()"
    >
      {{ TranslationEnum.Common.CREATE | translate }}...
    </span>
  </ng-template>
</div>
