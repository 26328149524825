<div class="d-inline-block" ngbDropdown #dropdown="ngbDropdown">
  <button
    type="button"
    class="open-more"
    id="dropdownManual"
    ngbDropdownAnchor
    (focus)="dropdown.open()"
  >
    <img src="/assets/images/icons/more.svg" alt="" class="dots" />
  </button>
  <div ngbDropdownMenu aria-labelledby="dropdownManual">
    <button ngbDropdownItem (click)="onChangeCard()">
      {{ TranslationEnum.Member.ACTION_CHANGE_CARD | translate }}
    </button>
    <button ngbDropdownItem (click)="releaseCard()">
      {{ TranslationEnum.Member.ACTION_RELEASE_CARD | translate }}
    </button>
    <button ngbDropdownItem (click)="resendRegisterEmail()">
      {{ TranslationEnum.Member.ACTION_RESEND_REGISTER_EMAIL | translate }}
    </button>
    <button ngbDropdownItem (click)="openModalDelete()">
      {{ TranslationEnum.Member.ACTION_DELETE_MEMBER | translate }}
    </button>
  </div>
</div>

<ng-template #confirmationModal let-modal>
  <app-confirmation-modal
    [titleText]="
      TranslationEnum.Table.DELETE_MODAL_TITLE
        | translate: { entityName: 'member' }
    "
    [bodyText]="
      member.status === 'Active'
        ? (TranslationEnum.Table.DELETE_ACTIVE_MEMBER | translate)
        : null
    "
    [confirmText]="TranslationEnum.Common.YES | translate"
    cancelText="{{ TranslationEnum.Common.CANCEL | translate }}"
    [modal]="modal"
  ></app-confirmation-modal>
</ng-template>
