export interface PaginationRequest {
  pageNumber: number;
  pageSize: number;
  ignoreMaxPageSize?: boolean;
}

export function paginationRequest(
  pageNumber = 1,
  pageSize = 10
): PaginationRequest {
  return {
    pageNumber,
    pageSize,
  } as PaginationRequest;
}
