import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CheckInResponse } from '../../../../models/check-in-response';
import { Router } from '@angular/router';
import { MemberDetailsService } from '../../../../services/member-details.service';
import { BarcodeCheck } from '../../../../models/barcode-check';
import { saveToasterNotification } from '../../../../store/actions/misc.actions';
import {
  CreateToasterError,
  CreateToasterSuccess,
} from '../../../../models/toaster-notification';
import { Store } from '@ngrx/store';
import { CheckinRequest } from '../../../../models/checkin-request';
import { dateFormat } from '../../../utilities/constants';
import { DatePipe } from '@angular/common';
import { TranslationEnum } from 'src/assets/i18n/translation-enum';
import {
  MembershipPeriod,
  MembershipType,
} from '../../../../models/membership';
import { Observable, Subject } from 'rxjs';
import { GymTrainer } from '../../../../models/user/user-gym-location';
import {
  getGymFeaturesSelector,
  getGymPermissionsSelector,
  getGymTrainers,
} from '../../../../store/selectors/user.selector';
import * as _ from 'lodash-es';
import { MemberMembership } from '../../../../models/member-membership';
import { TableActions } from '../../../../models/table-data/table-actions';
import { takeUntil } from 'rxjs/operators';
import { GymPermission } from '../../../../models/gym-permission';
import { Members } from '../../../../models/members';

export interface SidebarItem {
  label: string;
  url: string;
  selectedUrl: string;
  selected: boolean;
}
@Component({
  selector: 'app-check-in',
  templateUrl: './check-in.component.html',
  styleUrls: ['./check-in.component.scss'],
})
export class CheckInComponent implements OnInit {
  @ViewChild('webcamModal') webcamModal: TemplateRef<any>;
  TranslationEnum = TranslationEnum;
  membershipType = MembershipType;
  ptUsed = false;
  period = MembershipPeriod;
  editMembership: MemberMembership = null;

  sidebarInfo: SidebarItem[] = [
    {
      label: TranslationEnum.Menu.PROFILE,
      url: '/assets/images/icons/profile-checkin.svg',
      selectedUrl: '/assets/images/icons/profile-checkin-selected.svg',
      selected: true,
    },
    {
      label: TranslationEnum.Membership.MEMBERSHIP,
      url: '/assets/images/icons/profile-membership.svg',
      selectedUrl: '/assets/images/icons/profile-membership-selected.png',
      selected: false,
    },
    {
      label: TranslationEnum.Common.HISTORY,
      url: '/assets/images/icons/profile-history.png',
      selectedUrl: '/assets/images/icons/profile-history-selected.svg',
      selected: false,
    },
    {
      label: TranslationEnum.Common.SETTING,
      url: '/assets/images/icons/profile-settings.png',
      selectedUrl: '/assets/images/icons/profile-settings-selected.svg',
      selected: false,
    },
  ];

  sidebarClasses = {
    label: TranslationEnum.Classes.CLASSES,
    url: '/assets/images/icons/profile-classes.png',
    selectedUrl: '/assets/images/icons/profile-classes-selected.svg',
    selected: false,
  };
  sidebarDocuments = {
    label: TranslationEnum.Member.DOCUMENTS,
    url: '/assets/images/icons/profile-docs.png',
    selectedUrl: '/assets/images/icons/profile-docs-selected.svg',
    selected: false,
  };

  @Input() skipCheckIn: boolean = false;

  @Input() set memberships(values: MemberMembership[]) {
    const clonedData = _.cloneDeep(values);
    this._memberships =
      clonedData?.map((value) => ({
        ...value,
        consumedSessions: value.totalSessions === value.usedSessions,
        consumedPtSessions:
          value.personalTrainerTotalSessions ===
          value.personalTrainerUsedSessions,
      })) || [];
  }

  @Input()
  public memberDetails: Members;

  @Input() set checkInResponse(value: CheckInResponse) {
    if (!value) {
      return;
    }
    if (
      value.status === BarcodeCheck.OUTSIDE_HOURS ||
      value.status === BarcodeCheck.MULTIPLE_ACTIVE
    ) {
      this.status = 'warning';
    } else if (value.status === BarcodeCheck.NO_MEMBERSHIP) {
      this.status = 'empty';
    } else if (value.status === BarcodeCheck.SKIPPED) {
      this.status = 'skipped';
    } else this.status = 'success';
    this._checkInResponse = value;
  }

  get checkInResponse(): CheckInResponse {
    return this._checkInResponse;
  }

  _checkInResponse: CheckInResponse;
  checkInStatus = BarcodeCheck;
  trainers$: Observable<GymTrainer[]>;

  get memberships() {
    return this._memberships;
  }

  @Input() checkin: boolean;

  @Input() set memberId(value: string) {
    this._memberId = value;
  }

  @Input() memberName: string;

  @Input() locationId: string;

  @Output() close = new EventEmitter<boolean>();
  @Output() refreshCheckInInfo = new EventEmitter<string>();

  status: 'success' | 'empty' | 'warning' | 'skipped' = 'empty';
  profilePhoto;
  _memberId: string;
  _memberships: MemberMembership[];
  profilePhotoExists = true;
  pipeDateFormat = dateFormat;
  destroy$ = new Subject();

  constructor(
    private router: Router,
    private memberDetailsService: MemberDetailsService,
    private store: Store,
    public datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.trainers$ = this.store.select(getGymTrainers);

    this.store
      .select(getGymPermissionsSelector)
      .pipe(takeUntil(this.destroy$))
      .subscribe((values) => {
        if (values?.includes(GymPermission.BookClasses)) {
          this.sidebarInfo = [
            ...this.sidebarInfo.slice(0, 2),
            this.sidebarClasses,
            ...this.sidebarInfo.slice(2),
          ];
        }
      });

    this.store
      .select(getGymFeaturesSelector)
      .pipe(takeUntil(this.destroy$))
      .subscribe((values) => {
        if (
          values.memberDocumentsEnabled &&
          values.memberDocumentsUploadEnabled
        ) {
          this.sidebarInfo = [
            ...this.sidebarInfo.slice(0, this.sidebarInfo.length === 5 ? 4 : 3),
            this.sidebarDocuments,
            ...this.sidebarInfo.slice(this.sidebarInfo.length === 5 ? 4 : 3),
          ];
        }
      });
  }

  selectSidebarItem(item: SidebarItem) {
    this.editMembership = null;
    this.sidebarInfo.forEach((item) => (item.selected = false));
    item.selected = true;
    if (item.label === TranslationEnum.Menu.PROFILE) {
      this.refreshCheckInInfo.emit(this.checkInResponse.memberId);
    }
  }

  checkIn(membership: MemberMembership, pt = false) {
    if (
      (pt && membership.consumedPtSessions && membership.totalSessions <= -1) ||
      (!pt &&
        membership.consumedSessions &&
        membership.personalTrainerTotalSessions <= -1)
    ) {
      return;
    }
    if (pt && !membership.lastTrainer?.trainerId) {
      membership.selected = true;
      return;
    }
    if (pt && membership.lastTrainer?.trainerId) {
      this.ptUsed = true;
    } else if (!pt) {
      this.ptUsed = false;
    }
    this.memberDetailsService
      .checkIn(
        {
          memberId: this._memberId,
          membershipId: membership.id,
          locationId: this.locationId,
          hour: new Date().getHours(),
          minute: new Date().getMinutes(),
          trainerId: pt ? membership.lastTrainer?.trainerId : null,
        } as CheckinRequest,
        !membership.checkedIn
      )
      .subscribe((result) => {
        this.checkin = !membership.checkedIn;
        this.memberDetailsService.updateData.next(this._memberId);
        if (
          (result.status === BarcodeCheck.CHECKED_IN &&
            !membership.checkedIn) ||
          (result.status === BarcodeCheck.CHECKED_OUT && membership.checkedIn)
        ) {
          membership.checkedIn = !membership.checkedIn;
          if (membership.lastTrainer?.trainerId && pt) {
            membership.personalTrainerUsedSessions =
              membership.personalTrainerTotalSessions -
              result.sessionsRemaining;
            membership.consumedPtSessions =
              membership.personalTrainerTotalSessions ===
              membership.personalTrainerUsedSessions;
          } else {
            membership.usedSessions =
              membership.totalSessions - result.sessionsRemaining;
            membership.consumedSessions =
              membership.usedSessions === membership.totalSessions;
          }
          this.status = 'success';
        } else if (result.status === BarcodeCheck.OUTSIDE_HOURS) {
          this.status = 'warning';
          this.checkInResponse.status = BarcodeCheck.OUTSIDE_HOURS;
        } else if (result.status === BarcodeCheck.SKIPPED) {
          this.status = 'skipped';
        } else {
          this.store.dispatch(
            saveToasterNotification({
              toasterNotification: CreateToasterError(
                'An error occurred. Please try again'
              ),
            })
          );
        }
        this._memberships = this._memberships.filter(
          (m) => !m.consumedSessions || !m.consumedPtSessions
        );
      });
  }

  goToPos() {
    this.router.navigate(['/gym/pos'], {
      queryParams: { memberId: this.checkInResponse.memberId },
    });
    this.close.emit();
  }

  selectTrainer(trainer: GymTrainer, membership: MemberMembership) {
    membership.lastTrainer = trainer;
  }

  actionCallback(actionId: TableActions, parameter: string) {
    switch (actionId) {
      case TableActions.DELETE:
        this.deleteActiveMembership(parameter);
        break;
      case TableActions.EDIT:
        this.editItemRow(parameter);
    }
  }

  deleteActiveMembership(membershipId) {
    this.memberDetailsService
      .deleteMembership(membershipId, this.locationId)
      .subscribe(
        (result) => {
          if (!result) {
            this.store.dispatch(
              saveToasterNotification({
                toasterNotification: CreateToasterError(),
              })
            );
            return;
          }
          this.refreshCheckInInfo.emit(this.checkInResponse.memberId);
        },
        (error) => {
          this.store.dispatch(
            saveToasterNotification({
              toasterNotification: CreateToasterError(error),
            })
          );
        }
      );
  }

  editItemRow(parameter: string) {
    const item = this.memberships.find((item) => item.id === parameter);
    if (!item) {
      return;
    }

    const remainingSessions =
      item.totalSessions > -1 ? item.totalSessions - item.usedSessions : -1;
    const ptRemainingSessions =
      item.personalTrainerTotalSessions > -1
        ? item.personalTrainerTotalSessions - item.personalTrainerUsedSessions
        : -1;

    this.memberDetailsService
      .editMembership(
        item.id,
        this.locationId,
        item.dateEnded,
        remainingSessions,
        ptRemainingSessions
      )
      .subscribe(() => {
        this.store.dispatch(
          saveToasterNotification({
            toasterNotification: CreateToasterSuccess(
              'The membership was edited successfully!'
            ),
          })
        );
      });
  }

  membershipsSelected(): boolean {
    return (
      this.sidebarInfo.find(
        (item) => item.label === TranslationEnum.Membership.MEMBERSHIP
      )?.selected && !!this.memberships?.length
    );
  }

  historySelected() {
    return this.sidebarInfo.find(
      (item) => item.label === TranslationEnum.Common.HISTORY
    )?.selected;
  }

  classesSelected() {
    return this.sidebarInfo.find(
      (item) => item.label === TranslationEnum.Classes.CLASSES
    )?.selected;
  }

  docsSelected() {
    return this.sidebarInfo.find(
      (item) => item.label === TranslationEnum.Member.DOCUMENTS
    )?.selected;
  }

  settingsSelected() {
    return this.sidebarInfo.find(
      (item) => item.label === TranslationEnum.Common.SETTING
    )?.selected;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  dateFormat = dateFormat;
}
