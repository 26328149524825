import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';
import { MemberGroup, MembershipCategory } from '../../../models/membership-category';
import { EntityService } from '../../../services/entity.service';
import { TranslationEnum } from '../../../../assets/i18n/translation-enum';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-member-group-selector',
  templateUrl: './member-group-selector.component.html',
  styleUrls: ['./member-group-selector.component.scss']
})
export class MemberGroupSelectorComponent implements OnInit, AfterViewInit {
  @Input()
  locationId: string;

  @Input()
  set groupControl(control: AbstractControl){
    this._control = control as FormControl;
  }

  get groupControl(): FormControl {
    return this._control;
  }

  searchGroupsSubject = new BehaviorSubject<{ term: string; items: any[] }>(null);

  groupRequest = {
    linkedEntityId: null,
    paginationParam: {
      pageNumber: 1,
      pageSize: 10,
    },
    filters: {
      name: '',
    },
  };

  groupCreateName: string;
  createGroupInProgress = false;
  groups: MemberGroup[];
  selectGroup = false;

  @ViewChild('groupSelect', { static: true }) groupSelect: NgSelectComponent;
  @ViewChild('groupNotFound') notFound: TemplateRef<any>;

  readonly TranslationEnum = TranslationEnum;
  readonly createGroupOption = 'Create...';

  private _control: FormControl;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject('GroupService')
    private groupService: EntityService<MemberGroup>,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.groupService.setEntity('member/group');
    this.groupRequest.linkedEntityId = this.locationId;
    this.listenToGroupSearchChanges();
  }

  ngAfterViewInit() {
    this.groupSelect.notFoundTemplate = this.notFound;
  }

  detectChange(event) {
    if (event?.name === this.createGroupOption && !event.id) {
      this.addGroup();
      this.groupSelect.clearModel();
    }
  }

  addGroup() {
    if (this.createGroupInProgress || !this.groupCreateName) {
      return;
    }

    this.createGroupInProgress = true;

    const entity = {
      name: this.groupCreateName,
      locationId: this.locationId,
      canBeDeleted: false
    } as MembershipCategory;

    this.groupService.createEntity(entity)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.createGroupInProgress = false;
        this.searchGroupsSubject.next({
          term: this.groupCreateName,
          items: null,
        });
        this.selectGroup = true;
      });
  }

  private listenToGroupSearchChanges(): void {
    this.searchGroupsSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
        filter((value) => !!value?.term),
        switchMap((value) => {
          this.groupSelect.loading = true;
          this.groupRequest.filters.name = value?.term;
          this.groupCreateName = value?.term;
          return this.groupService.getEntityList(this.groupRequest);
        })
      )
      .subscribe((value) => {
        this.groups = value.data;

        if (this.selectGroup && this.groups?.length) {
          const group = this.groups.find(
            (cat) => cat.name === this.groupCreateName
          );

          if (group) {
            this.groupControl.setValue(group);
          }

          this.selectGroup = false;
          this.groupCreateName = null;
        }

        this.groupSelect.loading = false;
        this.changeDetectorRef.detectChanges();
      });
  }

}
