<div [formGroup]="memberForm" class="settings d-flex flex-column gap">
  <div class="d-flex flex-column gap">
    <span class="field-name">
      {{ TranslationEnum.Member.NAME | translate
      }}<span class="required-sign">*</span>
    </span>
    <input
      class="input"
      formControlName="name"
      type="text"
      [inputRestriction]="restrictInputType.NO_SPECIAL_CHARS"
      [class.error]="hasErrors(memberForm.get('name'))"
    />
  </div>
  <div class="d-flex flex-column gap">
    <span class="field-name">
      {{ TranslationEnum.Member.ADDRESS | translate }}
    </span>
    <div class="custom-input">
      <input
        class="input"
        formControlName="address"
        type="text"
        [class.error]="hasErrors(memberForm.get('address'))"
        [maxlength]="longTextMax"
      />
    </div>
  </div>
  <div class="d-flex flex-column gap">
    <span class="field-name">
      {{ TranslationEnum.Member.CONTACT_PHONE | translate }}
    </span>
    <div class="custom-input">
      <input
        type="text"
        formControlName="phone"
        class="input"
        [inputRestriction]="restrictInputType.DIGITS_ONLY"
        [class.error]="hasErrors(memberForm.get('phone'))"
        [maxlength]="phoneNumberLength"
      />
    </div>
  </div>
  <div class="d-flex flex-column gap">
    <span class="field-name">
      {{ TranslationEnum.Member.EMAIL | translate }}
    </span>
    <div class="custom-input">
      <input
        type="email"
        formControlName="email"
        class="input"
        [class.error]="hasErrors(memberForm.get('email'))"
      />
    </div>
  </div>
  <div class="d-flex flex-column gap">
    <span class="field-name">
      {{ TranslationEnum.Member.ID_NUMBER | translate }}
    </span>
    <div class="custom-input">
      <input
        type="text"
        formControlName="memberCI"
        class="input"
        [inputRestriction]="restrictInputType.NO_SPECIAL_CHARS"
        [class.error]="hasErrors(memberForm.get('memberCI'))"
        [maxlength]="ciMaxCharacters"
      />
    </div>
  </div>

  <div class="d-flex gap">
    <div class="d-flex flex-column gap w-50">
      <div class="d-flex flex-column gap">
        <span class="field-name">
          {{ TranslationEnum.Member.BIRTHDATE | translate }}
        </span>
        <app-member-birthday
          class="d-flex w-100"
          [memberBirthday]="memberBirthday"
          [isBirthdayValid]="isBirthdayValid"
          [displayHorizontally]="true"
        ></app-member-birthday>
      </div>
      <div class="d-flex flex-column gap">
        <span class="field-name">
          {{ TranslationEnum.Member.EMERGENCY_CONTACT_NAME | translate }}
        </span>
        <div class="custom-input">
          <input
            type="text"
            formControlName="emName"
            class="input"
            [inputRestriction]="restrictInputType.NO_SPECIAL_CHARS"
            [class.error]="hasErrors(memberForm.get('emName'))"
          />
        </div>
      </div>
      <div class="d-flex flex-column gap">
        <span class="field-name">
          {{ TranslationEnum.Member.SUBSCRIBER_ID | translate }}
        </span>
        <div class="custom-input">
          <input
            class="input"
            formControlName="customCardCode"
            type="text"
            [maxLength]="gymCustomCardLength"
            [class.error]="hasErrors(memberForm.get('customCardCode'))"
          />
        </div>
      </div>
    </div>
    <div class="d-flex flex-column gap w-50">
      <div class="d-flex flex-column gap">
        <span class="field-name">
          {{ TranslationEnum.Member.GENDER | translate }}
        </span>
        <ng-select
          [items]="gender"
          [multiple]="false"
          [maxSelectedItems]="1"
          id="memberGender"
          formControlName="sex"
          [class.error]="hasErrors(memberForm.get('sex'))"
          class="custom-edit-select"
        >
        </ng-select>
      </div>
      <div class="d-flex flex-column gap">
        <span class="field-name">
          {{ TranslationEnum.Member.EMERGENCY_PHONE_NUMBER | translate }}
        </span>
        <div class="custom-input">
          <input
            type="text"
            formControlName="emPhone"
            class="input"
            [inputRestriction]="restrictInputType.DIGITS_ONLY"
            [class.error]="hasErrors(memberForm.get('emPhone'))"
            [maxlength]="phoneNumberLength"
          />
        </div>
      </div>
      <div class="d-flex flex-column gap">
        <span class="field-name">
          {{ TranslationEnum.Member.INTERNAL_ID | translate }}
        </span>
        <div class="custom-input">
          <input
            class="input"
            formControlName="internalId"
            type="text"
            [class.error]="hasErrors(memberForm.get('internalId'))"
            [maxlength]="internalIdLength"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex flex-column gap">
    <app-member-group-selector
      [locationId]="locationId"
      [groupControl]="memberForm.get('group')"
    ></app-member-group-selector>
  </div>

  <app-member-consents
    [memberId]="member?.id"
    [locationId]="locationId"
    [memberSettings]="settings"
  ></app-member-consents>

  <div class="actions">
    <span class="btn btn-cancel">
      {{ TranslationEnum.Common.CANCEL | translate }}
    </span>

    <button
      (click)="saveChanges()"
      [disabled]="saveInProgress || memberForm.invalid"
      class="btn btn-save"
    >
      {{ TranslationEnum.Common.SAVE | translate }}
    </button>
  </div>
</div>
